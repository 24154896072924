import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';

import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';

import { HeaderBarComponent } from './pr-header-bar.component';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule,

    MatProgressBarModule,
    MatToolbarModule,
    UserAvatarModule,
    MatMenuModule
  ],
  declarations: [HeaderBarComponent],
  exports: [HeaderBarComponent]
})
export class HeaderBarModule { }
