<mat-toolbar class="d-flex justify-content-between" color="primary">
  <div class="d-flex">
    <a [routerLink]="auth.landingPage">
      <img class="c-pointer" width="160" height="40" style="outline: 0; height: auto; width: 100%" alt="ProRank" src="assets/images/logo.png" />
    </a>
  </div>

  <div class="d-flex" style="flex: 0 0 50%">
    <span class="spacer"></span>
    <ng-content select="[center]"></ng-content>
    <span class="spacer"></span>
  </div>

  <div class="d-flex align-items-center justify-content-end mat-body">
    <ng-content select="[right]"></ng-content>
    <div class="d-flex align-items-center" style="margin-left: 24px;" *ngIf="auth.name">
      <div *ngIf="auth.isAdmin" class="text-black mat-background-blue-100 d-none d-md-block" style="margin-right: 16px; padding: 4px 12px; border-radius: 4px;">Admin</div>
      <div [matMenuTriggerFor]="authMenu" class="cursor-pointer d-flex align-items-center">
        <div class="body-lg d-none d-md-block">{{ auth.name }}</div>
        <app-user-avatar [name]="auth.name" style="margin-left: 8px;"></app-user-avatar>
      </div>

      <mat-menu #authMenu yPosition="below">
        <a mat-menu-item routerLink="/profile/settings">Profile</a>
        <a mat-menu-item routerLink="/admin" *ngIf="auth.isAdmin">Admin</a>
        <button mat-menu-item (click)="auth.logout()">
          <span>Logout</span>
        </button>
      </mat-menu>
    </div>
  </div>
</mat-toolbar>

<mat-progress-bar class="mat-elevation-z5" color="accent" [mode]="progress.mode.value" [value]="progress.value.value" *ngIf="progress.mode.value !== 'none'"> </mat-progress-bar>
