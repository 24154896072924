import { Component, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Subject, Observable, of, merge } from 'rxjs';
import { takeUntil, debounceTime, switchMap, share, distinctUntilChanged, map } from 'rxjs/operators';
import { MatFormFieldAppearance, SubscriptSizing } from '@angular/material/form-field';

import { createSuccessResult } from 'src/app/utils/api-helpers';

import { ApiResult, Item } from 'src/app/services/api.service';
import { TemplateType } from 'src/app/enum';

@Component({
  selector: 'app-input-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.scss']
})
export class InputSelectComponent<T> implements OnDestroy {
  private destroy$ = new Subject<void>();
  public TemplateType = TemplateType;

  @Input() value?: T;
  @Input() text?: string;
  @Input() label?: string;
  @Input() placeholder?: string = '';
  @Input() subscriptSizingValue: SubscriptSizing = 'fixed';
  @Input() inputType = 'text';
  @Input() fieldClass?: string;
  @Input() api?: (searchValue?: string) => Observable<ApiResult<Item<T>[]>>;
  @Input() appearance: MatFormFieldAppearance = 'fill';
  @Input() disabled = false;
  @Input() clear = false;
  @Input() searchTemplateSelect = false;
  @Input() iconPrefix?: string;
  @Input() iconSuffix?: string;
  @Output() valueChange = new EventEmitter<T>();
  @Output() textChange = new EventEmitter<string>();
  @Output() itemChange = new EventEmitter<Item<T>>();
  @Input() width?: string;
  searchText$ = new Subject<string>();
  clearItems$ = new Subject<void>();
  items$ = merge(this.searchText$.pipe(debounceTime(300)), this.clearItems$.pipe(debounceTime(300))).pipe(
    map(x => x || undefined),
    distinctUntilChanged(),
    switchMap(
      searchValue =>
        this.api?.(searchValue).pipe(
          switchMap(result => {
            if (result.success && result.data.length === 0 && this.api) {
              return this.api('');
            }

            return of(result);
          })
        ) || of(createSuccessResult([]))
    ),
    share(),
    takeUntil(this.destroy$)
  );

  @ViewChild('inputRef', { static: false }) inputRef?: ElementRef<HTMLInputElement>;

  constructor() {}

  getInputClass() {
    if (this.iconPrefix && !this.iconSuffix) {
      return 'form-control form-control__prefix';
    } else if (this.iconSuffix && !this.iconPrefix) {
      return 'form-control form-control__suffix';
    } else if (this.iconPrefix && this.iconSuffix) {
      return 'form-control form-control__prefix form-control__suffix';
    } else {
      return 'form-control';
    }
  }

  setItem(item: Item<T>) {
    this.valueChange.emit(item.value);
    this.textChange.emit(item.text);
    this.itemChange.emit(item);

    if (this.clear) {
      if (this.inputRef) this.inputRef.nativeElement.value = '';
    } else {
      this.value = item.value;
      this.text = item.text;
    }

    if (this.inputRef) this.inputRef.nativeElement.blur();
  }

  public externallySetItem(item: Item<T>): void {
    this.value = item.value;
    this.text = item.text;
    this.searchText$.next(this.text || '');
  }

  displayFn = (item: Item<T>) => (item ? item.text : '');

  focus() {
    this.searchText$.next(this.text || '');
  }

  blur() {
    if (this.inputRef) this.inputRef.nativeElement.value = this.text || '';
    if (!this.text) {
      this.clearItems$.next();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
