import { inject, Injectable, NgModule, ProviderToken } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Routes, RouterModule, TitleStrategy, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { AdminGuard } from './guards/admin.guard';
import { AuthGuard } from './guards/auth.guard';
import { IframeGuard } from './guards/iframe.guard';
import { NegateAuthGuard } from './guards/auth-negate.guard';

// import { EmailActionsComponent } from './routes/email-actions/email-actions.component';
// import { ErrorAccessDeniedComponent } from './routes/errors/error-access-denied/error-access-denied.component';
import { ErrorNotFoundComponent } from './routes/errors/error-not-found/error-not-found.component';

function mapToCanActivate(providers: ProviderToken<{ canActivate: CanActivateFn }>[]): CanActivateFn[] {
  return providers.map(provider => (...params) => inject(provider).canActivate(...params));
}

const routes: Routes = [{
  path: 'admin',
  title: 'Admin',
  loadChildren: () => import('./routes/admin/admin.module').then(m => m.AdminModule),
  canActivate: mapToCanActivate([AdminGuard])
}, {
  path: 'login',
  title: 'Login',
  loadChildren: () => import('./routes/login/login.module').then(m => m.LoginModule),
  canActivate: mapToCanActivate([NegateAuthGuard])
}, {
  path: 'email',
  loadChildren: () => import('./routes/email-actions/email-actions.module').then(m => m.EmailActionsModule)
  //canActivate: mapToCanActivate([NegateAuthGuard])
}, {
  path: 'unsubscribe',
  loadChildren: () => import('./routes/manage-preferences/manage-preferences.module').then(m => m.ManagePreferencesModule)
}, {
  path: 'url-redirect',
  loadChildren: () => import('./routes/url-redirect/url-redirect.module').then(m => m.UrlRedirectModule)
}, {
  path: 'intake-form',
  title: 'Intake Form',
  loadChildren: () => import('./routes/intake-form/intake-form.module').then(m => m.IntakeFormModule),
  canActivate: mapToCanActivate([AuthGuard])
}, {
  path: 'bullhorn/:route',
  title: 'Bullhorn Iframe',
  loadChildren: () => import('./routes/iframe/iframe.module').then(m => m.IframeModule),
  canActivate: mapToCanActivate([IframeGuard]),
  data: { source: 'bullhorn' }
}, {
  path: 'salesforce/:route',
  title: 'Salesforce Iframe',
  loadChildren: () => import('./routes/iframe/iframe.module').then(m => m.IframeModule),
  canActivate: mapToCanActivate([IframeGuard]),
  data: { source: 'salesforce' }
}, {
  path: 'profile/settings',
  title: 'User Preferences',
  loadChildren: () => import('./routes/profile/profile.module').then(m => m.ProfileModule)
}, {
  path: '',
  loadChildren: () => import('./routes/home/home.module').then(m => m.HomeModule),
  canActivate: mapToCanActivate([AuthGuard])
}, {
  path: '**',
  component: ErrorNotFoundComponent
}];

@Injectable({ providedIn: 'root' })
export class TemplatePageTitleStrategy extends TitleStrategy {
  constructor(private readonly title: Title) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const pageTitle = this.buildTitle(routerState);
    if (pageTitle !== undefined) {
      const title = `ProRank | ${pageTitle}`;

      if (!this.title.getTitle().startsWith(title)) {
        this.title.setTitle(title);
      }
    }
  }
}

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
  providers: [
    { provide: TitleStrategy, useClass: TemplatePageTitleStrategy }
  ]
})
export class AppRoutingModule { }
