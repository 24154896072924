import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent {

  @Input()
  set name(value: string) {
    value = value?.trim();

    if (value) {
      const words = value.split(' ');
      if (words.length === 2) {
        const initials = words[0].charAt(0) + words[1].charAt(0);
        this.letter = initials.toUpperCase();
      } else {
        this.letter = '';
      }
      this.i = this.simpleHash(value);
    }
  }

  @Input() src?: string;
  @Input() size?: string;

  letter?: string;
  i?: number;

  constructor() { }

  simpleHash(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = hash + char;
      hash &= hash; // Convert to 32bit integer
    }
    return new Uint32Array([hash])[0] % 16 + 1;
  }
}
