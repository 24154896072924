import { inject, NgModule, ProviderToken } from '@angular/core';
import { Routes, RouterModule, CanActivateFn } from '@angular/router';

import { CoreAppGuard } from 'src/app/guards/core-app.guard';

import { HomeComponent } from './home.component';

function mapToCanActivate(providers: ProviderToken<{ canActivate: CanActivateFn }>[]): CanActivateFn[] {
  return providers.map(
    provider =>
      (...params) =>
        inject(provider).canActivate(...params)
  );
}

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: 'dashboard',
        title: 'Dashboard',
        loadChildren: () => import('./../dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: mapToCanActivate([CoreAppGuard])
      },
      {
        path: 'jobs',
        title: 'Jobs',
        loadChildren: () => import('./../job-postings/job-postings.module').then(m => m.JobPostingsModule),
        canActivate: mapToCanActivate([CoreAppGuard])
      },
      {
        path: 'talent-communities',
        title: 'Talent Communities',
        loadChildren: () => import('./../talent-communities/talent-communities.module').then(m => m.TalentCommunitiesModule),
        canActivate: mapToCanActivate([CoreAppGuard])
      },
      {
        path: 'job/:jobId/:selectedTab',
        loadChildren: () => import('./../job-posting/job-posting.module').then(m => m.JobPostingModule),
        canActivate: mapToCanActivate([CoreAppGuard])
      },
      {
        path: 'job/:jobId/:selectedTab/:filter',
        loadChildren: () => import('./../job-posting/job-posting.module').then(m => m.JobPostingModule),
        canActivate: mapToCanActivate([CoreAppGuard])
      },
      {
        path: 'candidates',
        title: 'Candidates',
        loadChildren: () => import('./../candidates-db/candidates-db.module').then(m => m.CandidatesDbModule),
        canActivate: mapToCanActivate([CoreAppGuard])
      },
      {
        path: 'candidate/:view',
        loadChildren: () => import('./../candidate/candidate.module').then(m => m.CandidateModule),
        canActivate: mapToCanActivate([CoreAppGuard])
      },
      {
        path: 'watch-list',
        title: 'Watch List',
        loadChildren: () => import('./../watch-list/watch-list.module').then(m => m.WatchListModule),
        canActivate: mapToCanActivate([CoreAppGuard])
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule {}
