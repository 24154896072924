import { Injectable, OnDestroy } from '@angular/core';
import { Subject, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import { ApiService } from 'src/app/services/api.service';
import { HttpGetRequest, HttpPostRequest } from 'src/app/services/http.service';

import { AnalysisDetail } from './candidates.service';
import { mapSuccessData } from 'src/app/utils/rxjs-operators';

@Injectable({
  providedIn: 'root'
})
export class XrefService implements OnDestroy {
  private destroy$ = new Subject<void>();

  get = <T>(request: HttpGetRequest) => of(request).pipe(switchMap(x => this.api.get<T>({ ...x, path: `xref/${x.path}` })));

  post = <T>(request: HttpPostRequest) => of(request).pipe(switchMap(x => this.api.post<T>({ ...x, path: `xref/${x.path}` })));

  query = <T>(request: HttpPostRequest) => of(request).pipe(switchMap(x => this.api.post<T>({ ...x, path: `xref/${x.path}-query` })));

  getCampaigntypes = () =>
    this.api
      .get<Campaigntype[]>({
        path: 'xref/campaign-types'
      })
      .pipe(
        mapSuccessData(data => data),
        takeUntil(this.destroy$)
      );

  getCandidatePrioritizations = () =>
    this.api
      .get<CandidatePrioritization[]>({
        path: 'xref/candidate-prioritization'
      })
      .pipe(
        mapSuccessData(data => data),
        takeUntil(this.destroy$)
      );
  getCandidatePrioritizations2 = () =>
    this.api
      .get<any>({
        path: 'xref/contact-info-filters'
      })
      .pipe(
        mapSuccessData(data => data),
        takeUntil(this.destroy$)
      );

  getNotesTypes = () =>
    this.api
      .get<any[]>({
        path: 'xref/note-types'
      })
      .pipe(takeUntil(this.destroy$));

  getOutreachVars = () =>
    this.api
      .get<OutreachVars[]>({
        path: 'xref/outreach-vars'
      })
      .pipe(takeUntil(this.destroy$));

  getOutreachsteps = () =>
    this.api
      .get<OutreachStep[]>({
        path: 'xref/outreach-steps'
      })
      .pipe(takeUntil(this.destroy$));


  getContactInfoFilters = () =>
    this.api
      .get<any>({
        path: 'xref/contact-info-filters'
      })
      .pipe(
        mapSuccessData(data => data),
        takeUntil(this.destroy$)
      );

  getDataSourceFilters = () =>
    this.api
      .get<any>({
        path: 'xref/data-source-filters'
      })
      .pipe(mapSuccessData(data => data), takeUntil(this.destroy$));

  getEmailTypes = () =>
    this.api
      .get<EmailType[]>({
        path: 'xref/email-types'
      })
      .pipe(takeUntil(this.destroy$));

  retrieveContactInfoFilters = () =>
    this.api
      .get<any>({
        path: 'xref/contact-info-filter'
      })
      .pipe(
        mapSuccessData(data => data),
        takeUntil(this.destroy$)
      );

  retrieveDataSourceFilters = () =>
    this.api
      .get<any>({
        path: 'xref/data-source-filter'
      })
      .pipe(mapSuccessData(data => data), takeUntil(this.destroy$));

  constructor(private api: ApiService) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

export interface RuleWeight {
  ruleWeightId: number;
  ruleWeightDesc: string;
}

export interface SkillType {
  skillTypeId: number;
  skillType: string;
}

export interface JobSubFamily {
  jobSubFamilyId: number;
  jobSubFamilyDesc: string;
  jobFamilyId: number;
  jobFamilyDesc: string;
}

export interface JobRoleLevel {
  jobRoleLevelId: number;
  jobRoleLevel: string;
}

export interface JobRoleOption {
  optId: number;
  optDesc: string;
}

export interface CareerLevel {
  careerLevelId: number;
  careerLevel: string;
}

export interface ExperienceBand {
  expBandId: number;
  expBandDesc: string;
}

export interface NoteType {
  noteTypeId: number;
  noteType: string;
}

export interface Company {
  companyId: string;
  companyName: string;
}

export interface City {
  geoId: string;
  cityState: string;
}

export interface PositionType {
  positionTypeId: number;
  positionType: string;
}

export interface JobRole {
  jobRoleId: string;
  jobRole: string;
}

export interface DegreeType {
  degreeTypeId: number;
  degreeTypeDesc: string;
}

export interface FieldOfStudy {
  majorId: number;
  majorDesc: string;
}

export interface School {
  companyId: string;
  companyName: string;
}

export interface Industry {
  skillId: string;
  skillName: string;
}

export interface PayRate {
  payRateId: number;
  payRateDesc: string;
}

export interface WorkAuthorization {
  workStatusId: number;
  workStatusDesc: string;
}

export interface Country {
  countryId: number;
  countryName: string;
}

export interface LastContact {
  lastUpdateValueId: string;
  lastUpdateValue: string;
}

export interface LastUpdate {
  lastUpdateValueId: number;
  lastUpdateValue: string;
}

export interface DefaultView {
  defaultViewId: number;
  defaultViewText: string;
}

export interface Region {
  regionId: string;
  regionName: string;
}

export interface GeoDistance {
  distanceId: number;
  distanceDesc: string;
}

export interface City {
  geoId: string;
  cityState: string;
}

export interface RuleGroup {
  ruleGroupId: number;
  ruleGroupDesc: string;
  ruleGroupShort: string;
  apiKey: keyof AnalysisDetail;
  toolTip: string;
}

export interface ProgressStages {
  stageId: number;
  stageDesc: string;
}

export interface JobFamily {
  jobFamilyId: number;
  jobFamilyDesc: string;
  jobGroupId: number;
}

export interface JobTemplateType {
  templateTypeId: number;
  templateTypeDesc: string;
}

export interface ProgressSortBy {
  sortById: number;
  sortByDesc: string;
}

export interface TalentSearchSortBy {
  sortById: number;
  sortByDesc: string;
}

export interface Campaigntype {
  campaignId: number;
  campaignDesc: string;
}

export interface CandidatePrioritization {
  priorityId: number;
  priorityDesc: string;
}

export interface OutreachVars {
  varId: number;
  varName: string;
  varDesc: string;
  varType: number;
  varTypeDesc: string;
}

export interface OutreachStep {
  outreachStepId: number;
  outreachStepDesc: string;
  sort: number;
  campaignId: number;
  campaignDesc: string;
}
export interface LastUsedPresets {
  lastUsedId: number;
  lastUsedDesc: string;
}

export interface WorkStatus {
  workStatusId: number;
  workStatusDesc: string;
}

export interface CareerLevel {
  careerLevelId: number;
  careerLevel: string;
}

export interface UserRole {
  userRoleId: number;
  roleName: string;
}

export interface PositionType {
  positionTypeId: number;
  positionType: string;
}

export interface JobRoleLevel {
  jobRoleLevelId: number;
  jobRoleLevel: string;
}

export interface EmailType {
  emailTypeId: number;
  emailTypeDesc: string;
}
