<div class="input-group" [ngClass]="{ 'autocomplete-group': iconPrefix || iconSuffix }" [class]="fieldClass || ''">
  <!--optional, renders an icon in the left side of the input if the component passes one-->
  <ng-container *ngIf="iconPrefix">
    <span class="material-symbols-outlined input-group-text input-group-text__prefix">
      {{ iconPrefix }}
    </span>
  </ng-container>

  <input
    [class]="getInputClass()"
    [ngClass]="{
      'input-with-prefix': iconPrefix,
      'input-with-suffix': iconSuffix,
    }"
    #inputRef1
    [type]="inputType"
    [placeholder]="placeholder || ''"
    [value]="value || ''"
    (input)="textChange$.next(inputRef1.value)"
    (focus)="focus(inputRef1.value)"
    (focusout)="blur(inputRef1.value)"
    [matAutocomplete]="$any(matAutocomplete)"
    [matAutocompleteDisabled]="disabled || !matAutocomplete"
    (keyup.enter)="emit(inputRef1.value)"
    [disabled]="disabled"
    [min]="min"
    [max]="max"
    *ngIf="!multiline" />

  <textarea
    class="form-control"
    #inputRef2
    [placeholder]="placeholder || ''"
    [value]="value || ''"
    (input)="textChange$.next(inputRef2.value)"
    (focus)="focus(inputRef2.value)"
    (focusout)="blur(inputRef2.value)"
    rows="3"
    [disabled]="disabled"
    *ngIf="multiline">
  </textarea>

  <ng-container *ngIf="iconSuffix">
    <span class="material-symbols-outlined input-group-text input-group-text__suffix">
      <!--display a spinner while autocomplete is searching for results-->
      <span *ngIf="iconSuffix && iconSuffix === 'loading'">
        <div class="spinner-border" role="status"></div>
      </span>
      <!--display an icon when the spinner is inactive-->
      <ng-container *ngIf="iconSuffix && iconSuffix !== 'loading'">
        <span>{{ iconSuffix }}</span>
      </ng-container>
    </span>
  </ng-container>
</div>
