<app-input-text
  [fieldClass]="'omni-search'"
  placeholder="Search for a job or candidate"
  [clear]="true"
  iconPrefix="search"
  [iconSuffix]="isLoading ? 'loading' : ''"
  [matAutocomplete]="auto"
  (valueChange)="searchText$.next($event)">
</app-input-text>

<mat-autocomplete #auto disableRipple="true" panelClass="custom-autocomplete" (closed)="searchText$.next('')">
  <ng-container *ngFor="let group of data$ | async; let i = index">
    <mat-option class="mat-option-disabled text-white" disabled>
      {{ group.filter.title }}
    </mat-option>

    <mat-option *ngFor="let item of group.items" (click)="group.filter.click(item)">
      <ng-container [ngSwitch]="group.filter.title">
        <div class="w-100 row" *ngSwitchCase="OmniSearchFilters.CANDIDATES">
          <div class="col-4 align-items-center">
            <div class="h-100 d-flex align-items-center text-truncate">
              <app-user-avatar [src]="item.value.imgUrl" size="30px" class="me-4" *ngIf="item.value.imgUrl"> </app-user-avatar>

              <mat-icon class="mat-color-gray-600 md-icon-30 me-4" *ngIf="!item.value.imgUrl">account_circle</mat-icon>

              <div class="d-flex flex-column text-truncate">
                <div class="d-flex align-items-center mb-1">
                  <span class="d-block body fw-medium text-truncate">{{ item.value.displayName | titlecase }}</span>
                  <!-- TODO We dont receive the sourcedMethodId or openToWorkFlag here. Fix on backend if needed -->
                  <!-- <div class="flag flag__blue me-2" *ngIf="item.sourcedMethodId === 1000">Applicant</div> -->
                  <!-- <div class="flag flag__blue me-2" *ngIf="item.sourcedMethodId === 2000 || item.sourcedMethodId === 3000">Sourced</div> -->
                  <!-- <div class="flag flag__green me-2" *ngIf="item.openToWorkFlag === 1">Open to Work</div> -->
                  <div class="flag flag__red me-2" *ngIf="item.value.isRestricted === CandidateRestrictionStatus.RESTRICTED">Restricted</div>
                  <div class="flag flag__green me-2" *ngIf="item.value.isFollowed === CandidateFollowStatus.FOLLOWED">Followed</div>
                </div>

                <div class="hint body-sm fw-normal lh-1 text-nowrap text-truncate">
                  {{ item.value.jobTitle ? (item.value.jobTitle | titlecase) : '' }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 align-items-center">
            <div class="h-100 d-flex align-items-center text-truncate">
              <mat-icon class="md-icon-18 me-0 mat-color-accent-500">business</mat-icon>
              <span class="body-sm ms-2 text-truncate"> {{ item.value.companyName || 'No Current Company' }}</span>
            </div>
          </div>
          <div class="col-4">
            <div class="h-100 d-flex align-items-center text-truncate">
              <mat-icon class="md-icon-16 me-0 mat-color-accent-500">location_on</mat-icon>
              <span class="body-sm ms-2 text-truncate">
                {{ item.value.cityState || 'No Location Available' }}
              </span>
            </div>
          </div>
        </div>

        <div class="d-flex align-items-center" *ngSwitchCase="OmniSearchFilters.JOBS">
          <mat-icon class="md-icon-30 mat-color-gray-600 me-4">work</mat-icon>
          <div>
            <div class="mb-1">
              <span class="fw-medium">{{ item.value.jobTitle }}</span>
              <span class="fw-normal"> ({{ item.value.jobId }})</span>
            </div>
            <div class="fw-normal mat-color-gray-600">{{ item.value.companyName }}</div>
          </div>
        </div>
        <div *ngSwitchDefault>
          <span>
            {{ item?.text }}
          </span>
        </div>
      </ng-container>
    </mat-option>
  </ng-container>
</mat-autocomplete>
