<ng-container *ngIf="{ value: items$ | async } as items">
  <div class="input-group" [ngClass]="{ 'autocomplete-group': iconPrefix || iconSuffix }">
    <!--optional, renders an icon in the left side of the input if the component passes one-->
    <ng-container *ngIf="iconPrefix">
      <span class="material-symbols-outlined input-group-text input-group-text__prefix">
        {{ iconPrefix }}
      </span>
    </ng-container>

    <!--renders a text field with an autocomplete that shows while the user is typing and goes away when the user clicks an option-->
    <input
      #inputRef
      [type]="inputType"
      [class]="getInputClass()"
      [ngClass]="{
        'input-with-prefix': iconPrefix,
        'input-with-suffix': iconSuffix
      }"
      [value]="text || ''"
      [placeholder]="placeholder"
      [disabled]="disabled"
      (input)="searchText$.next(inputRef.value)"
      (focus)="focus()"
      (blur)="blur()"
      [matAutocomplete]="auto"
      [matAutocompleteDisabled]="!api" />

    <!--optional, renders an icon in the right side of the input if the component passes one-->
    <ng-container *ngIf="iconSuffix">
      <span class="material-symbols-outlined input-group-text input-group-text__suffix">
        <!--display a spinner while autocomplete is searching for results-->
        <span *ngIf="items.value && items.value.finished === false">
          <div class="spinner-border" role="status"></div>
        </span>
        <!--display a search icon when the spinner is inactive-->
        <ng-container *ngIf="iconSuffix">
          <span *ngIf="!items.value || (items.value && items.value.finished !== false)">{{ iconSuffix }}</span>
        </ng-container>
      </span>
    </ng-container>
  </div>

  <!--renders the autocomplete attached to the input-->
  <mat-autocomplete #auto [displayWith]="displayFn" (optionSelected)="setItem($event.option.value)">
    <ng-container *ngIf="items.value && items.value.success">
      <mat-option *ngFor="let item of items.value.data" style="padding-left: 0; padding-right: 0;" [value]="item" (mousedown)="$event.preventDefault()">
        <span *ngIf="!searchTemplateSelect">{{ item.typeaheadText || item.text }}</span>

        <div class="w-100" *ngIf="searchTemplateSelect" [matTooltip]="item.typeaheadText || item.text" matTooltipPosition="right">
          <div class="w-100 text-truncate" style="font-size: 13px;">{{ item.typeaheadText || item.text }}</div>
          <div class="w-100 mat-color-gray-400" style="font-size: 10px;" *ngIf="item.templateTypeId">
            <ng-container *ngIf="item.templateTypeId === TemplateType.ARCHETYPE">ProRank Job Archetype</ng-container>  
            <ng-container *ngIf="item.templateTypeId === TemplateType.SAVED_SEARCH">Saved Search</ng-container>  
          </div>
        </div>
      </mat-option>
 
    </ng-container>
  </mat-autocomplete>
</ng-container>
