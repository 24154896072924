import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ApiService, DataTable } from './../api.service';
import { mapSuccessData, successData } from 'src/app/utils/rxjs-operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CandidatesService implements OnDestroy {
  private destroy$ = new Subject<void>();

  sidebarShow$ = new BehaviorSubject<BaseCandidate | undefined>(undefined);
  sidebarShowTable$ = new BehaviorSubject<{ table: DataTable<BaseCandidate>; i: number } | undefined>(undefined);
  sidebarClose$ = new Subject<void>();

  get = (body = {}, params = {}) =>
    this.api
      .post<DataTable<Candidate>>({
        path: 'candidates',
        params,
        body
      })
      .pipe(takeUntil(this.destroy$));

  getById = (profileRef: string) =>
    this.api
      .get<CandidateHeader>({
        path: 'candidate/header',
        params: { profileRef }
      })
      .pipe(takeUntil(this.destroy$));

  overview = (profileRef: string) =>
    this.api
      .get<CandidateOverview>({
        path: 'candidate/overview',
        params: { profileRef }
      })
      .pipe(takeUntil(this.destroy$));

  jobsMatched = (profileRef: string) =>
    this.api
      .get<JobsMatched[]>({
        path: 'candidate/jobs-matched',
        params: { profileRef }
      })
      .pipe(takeUntil(this.destroy$));

  questionnaireJobsMatched = (profileRef: string, sus: string) =>
    this.api
      .get<any[]>({
        path: 'candidate/candidate-jobs-matched',
        params: { profileRef, sus }
      })
      .pipe(takeUntil(this.destroy$));

  analysisDetail = (profileRef: string, jobId: number) =>
    this.api
      .get<AnalysisDetail>({
        path: 'candidate/analysisDetail',
        cacheDuration: 0,
        params: { profileRef, jobId }
      })
      .pipe(takeUntil(this.destroy$));

  saveProfile = (profile: Profile, jwt: string) =>
    this.api
      .post<void>({
        path: 'profile/bio',
        params: { jwt },
        body: { profile }
      })
      .pipe(takeUntil(this.destroy$));

  shortList = (candidates: Candidate[]) =>
    this.api
      .post<void>({
        path: 'candidate/shortList',
        body: { candidates }
      })
      .pipe(takeUntil(this.destroy$));

  progress = (candidates: Candidate[]) =>
    this.api
      .post<void>({
        path: 'candidate/progress',
        body: { candidates }
      })
      .pipe(takeUntil(this.destroy$));

  saveCandidateBulk = (candidates: Candidate[]) =>
    this.api
      .post<void>({
        ////////////
        path: 'recruiter/candidateBulk',
        body: { candidates }
      })
      .pipe(takeUntil(this.destroy$));

  updateProfiles = () =>
    this.api
      .post<void>({
        path: 'candidate/update-profiles'
      })
      .pipe(takeUntil(this.destroy$));

  showSidebar(item: BaseCandidate) {
    this.sidebarShowTable$.next(undefined);
    this.sidebarShow$.next(item);
  }

  showSidebarTable(table: DataTable<BaseCandidate>, i: number) {
    this.sidebarShow$.next(undefined);
    this.sidebarShowTable$.next({ table, i });
  }

  closeSidebar() {
    this.sidebarClose$.next();
  }

  constructor(private api: ApiService, private http: HttpClient) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  addToCampaignBulk = (bulkAddToCampaign: bulkAddToCampaign) =>
    this.api
      .post<void>({
        path: 'campaign/add-candidates-bulk',
        body: bulkAddToCampaign
      })
      .pipe(takeUntil(this.destroy$));

  addToShortList = (bulkAddToShort: bulkAddToShortList) =>
    this.api
      .post<void>({
        path: 'candidate/shortList',
        body: bulkAddToShort
      })
      .pipe(takeUntil(this.destroy$));
  changeStage = (candidates: bulkChangeStageList) =>
    this.api
      .post<void>({
        path: 'candidate/progress',
        body: candidates
      })
      .pipe(takeUntil(this.destroy$));

  hideCandidateBulk = (candidates: candidateHideBulk) =>
    this.api
      .post<void>({
        path: 'recruiter/candidateBulk',
        body: candidates
      })
      .pipe(takeUntil(this.destroy$));

  getHeaderById = (profileRef: string, jobId?: number) =>
    this.api
      .get<CandidateHeaderResponse>({
        path: 'candidate/header',
        params: { profileRef, jobId }
      })
      .pipe(
        mapSuccessData((x: CandidateHeaderResponse) => ({
          ...x,
          phonesString: x.phones
            ?.slice(1)
            .map(p => p.phoneNumber)
            .toString(),
          emailsString: x.emails
            ?.slice(1)
            .map(p => p.email)
            .toString()
        })),

        takeUntil(this.destroy$)
      );

  getOverview = (profileRef: string) =>
    this.api
      .get<CandidateOverviewResponse>({
        path: 'candidate/overview',
        params: { profileRef }
      })
      .pipe(successData(), takeUntil(this.destroy$));

  getAnalysisDetail = (profileRef: string, jobId: number) =>
    this.api
      .get<AnalysisDetail>({
        path: 'candidate/analysisDetail',
        cacheDuration: 0,
        params: { profileRef, jobId }
      })
      .pipe(
        successData(),

        takeUntil(this.destroy$)
      );

  getJobsMatched = (profileRef: string, jobId: number) =>
    this.api
      .get<JobMatchResponse>({
        path: 'candidate/jobs-matched',
        params: { profileRef, jobId }
      })
      .pipe(
        successData(),

        takeUntil(this.destroy$)
      );

  getResume = (profileRef: string) =>
    this.api
      .post<{ allowVersions: boolean; versions: FileVersion[] }>({
        path: 'resume/versions',

        body: { profileRef }
      })
      .pipe(
        //successData(),
        takeUntil(this.destroy$)
      );

  getActivity = (profileRef: string, jobId: number) =>
    this.api
      .get<Activity[]>({
        path: 'candidate/activity-streamer',
        cacheDuration: 0,
        params: { profileRef, jobId }
      })
      .pipe(successData(), takeUntil(this.destroy$));

  search = (_searchValue?: string) =>
    this.api
      .post<DataTable<any>>({
        path: 'candidates',
        body: { searchValue: _searchValue || '' }
      })
      .pipe(
        mapSuccessData(data => data.dataTable),
        takeUntil(this.destroy$)
      );

  requestContactInfo = (profileRef: string, jobId?: number) =>
    this.api
      .post<CandidateHeaderResponse>({
        path: 'candidate/request-contact-info',
        body: { profileRef, jobId }
      })
      .pipe(
        mapSuccessData((x: CandidateHeaderResponse) => ({
          ...x,
          phones: !x.phones || x.phones?.length === 0 ? [{ phoneNumber: 'No phone found' }] : x.phones,
          emails: !x.emails || x.emails?.length === 0 ? [{ email: 'No email found' }] : x.emails
        })),

        mapSuccessData((x: CandidateHeaderResponse) => ({
          ...x,
          phonesString: x.phones
            ?.slice(1)
            .map(p => p.phoneNumber)
            .toString(),
          emailsString: x.emails
            ?.slice(1)
            .map(p => p.email)
            .toString()
        })),
        takeUntil(this.destroy$)
      );

  getResumeBlob = (jwt: string) =>
    this.http
      .get('api/v2/resume/download', {
        responseType: 'blob',
        params: { jwt }
        //headers: { 'Content-Type': 'application/octet-stream' }
      })
      .pipe(takeUntil(this.destroy$));

  unmask = (profileRef: string, jobId: number) =>
    this.api
      .post<any>({
        path: 'candidate/unmask',
        body: { profileRef, jobId }
      })
      .pipe(takeUntil(this.destroy$));

  updateContactInfo = (updateContactBody: updateContactInfoBody) =>
    this.api
      .post<CandidateHeaderResponse>({
        path: 'candidate/update-contact-info',
        body: updateContactBody
      })
      .pipe(
        mapSuccessData((x: CandidateHeaderResponse) => ({
          ...x,
          phonesString: x.phones
            ?.slice(1)
            .map(p => p.phoneNumber)
            .toString(),
          emailsString: x.emails
            ?.slice(1)
            .map(p => p.email)
            .toString()
        })),
        takeUntil(this.destroy$)
      );

  updateName = (profileRef: string, firstName?: string, lastName?: string, preferredName?: string) =>
    this.api
      .post<any>({
        path: 'candidate/update-name',
        body: { profileRef, firstName, lastName, preferredName }
      })
      .pipe(takeUntil(this.destroy$));

  updateCandidateLocation = (profileRef: string, geoId?: number) =>
    this.api
      .post<any>({
        path: 'candidate/update-location',
        body: { profileRef, geoId }
      })
      .pipe(takeUntil(this.destroy$));

  updateCandidateWorkStatus = (profileRef: string, workStatusId: number) =>
    this.api
      .post<any>({
        path: 'candidate/update-workStatus',
        body: { profileRef, workStatusId }
      })
      .pipe(takeUntil(this.destroy$));

  updateCandidateWorkPreferences = (profileRef: string, workPreferences: WorkPreferences) =>
    this.api
      .post<any>({
        path: 'candidate/update-workPreferences',
        body: { profileRef, ...workPreferences }
      })
      .pipe(takeUntil(this.destroy$));

  candidateQuestionnaire = (profile: any, jwt: string) =>
    this.api
      .post<void>({
        path: 'profile/update',
        params: { jwt },
        body: { profile }
      })
      .pipe(takeUntil(this.destroy$));

  updateDemographics = (profile: any, sus: string) =>
    this.api
      .post<void>({
        path: 'candidate/update-demographics',
        params: { sus },

        body: { ...profile }
      })
      .pipe(takeUntil(this.destroy$));

  getDemographics = (profileRef: string, sus: string) => {
    return this.api
      .get<any>({
        path: 'candidate/retrieve-demographics',

        params: { profileRef, sus}
      })
      .pipe(takeUntil(this.destroy$));
  };
  getworkPreferencesAndStatus = (profileRef: string) =>
    this.api
      .get<any>({
        path: 'candidate/retrieve-workPreferencesAndStatus',

        params: { profileRef }
      })
      .pipe(takeUntil(this.destroy$));

  updateWorkPreferencesAndStatus = (profile: any) =>
    this.api
      .post<void>({
        path: 'candidate/update-workPreferencesAndStatus',

        body: { ...profile }
      })
      .pipe(takeUntil(this.destroy$));

  getEEOC = (profileRef: string) =>
    this.api
      .get<any>({
        path: 'candidate/retrieve-eeoc',

        params: { profileRef }
      })
      .pipe(takeUntil(this.destroy$));

  updateEEOC = (profile: any) =>
    this.api
      .post<void>({
        path: 'candidate/update-eeoc',

        body: { ...profile }
      })
      .pipe(takeUntil(this.destroy$));
}

export interface BaseCandidate {
  jobId?: number;
  profileId: string;
  profileRef: string;
  displayName: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  companyName: string;
  cityState: string;
  email: string;
  mobilePhone: string;
  homePhone: string;
  linkedInUrl: string;
  indeedUrl: string;
  haveResumeFlag: string;
  imgUrl?: string;
  proRank: string;
  proScore?: string;
  careerScore: string;
  availabilityScore: string;
  skillScore: string;
  educationScore: string;
  canRequestContactInfoFlag: '0' | '1';
  priorityId: string;
  priorityDesc: string;
  hasRepliedFlag: '0' | '1';
  lastContactedDate: string;
  showHideReject: 0 | 1;
  openToWorkFlag: 0 | 1;
  isRestricted: 0 | 1;
  isFollowed: 0 | 1;
  isAts: 0 | 1;
}

export interface Candidate extends BaseCandidate {
  stage?: string;
  status?: string;
  likelihood: string;
  lastUpdatedDate: string;
  jobsMatched: string;
  watchListedFlag: '0' | '1';
  source: string;
  isActive: '0' | '1';
  isShortListed: '0' | '1';
  isInterested: '0' | '1';
  isFailed: '0' | '1';
  isRestricted: 0 | 1;
  isFollowed: 0 | 1;
  assignedToId: string;
  userId: number;
  groupId: string;
  loading: boolean;
  sourcedMethodId: number;
}

export interface CandidateProgress extends BaseCandidate {
  campaignId: number;
  campaignDesc: string;
  nextOutreachTypeId: number;
  nextOutreachTypeDesc: string;
  nextOutreachDate: string;
  stageId: number;
  stageDesc: string;
  rejectPartyId: number;
  rejectPartyDesc: string;
  rejectReasonId: number;
  rejectReasonDesc: string;
  statusId: number;
  statusDesc: string;
}

export interface CandidateHeader {
  profileRef: string;
  displayName: string;
}

export interface Profile {
  profileId: string;
  profileRef: string;
  firstName: string;
  lastName: string;
  email: string;
  genderId: string;
  geoId: string;
  cityState: string;
  countryId: string;
  homePhone: string;
  cellPhone: string;
  workStatusId: string;
  positionType: string[];
  desiredHourlyRate: string;
  availableDate: string;
  getNewFlag: string;
}

export interface CandidateOverview {
  contacts: Contact[];
  education: Education[];
  priorJobs: PriorJob[];
  imgUrl: string;
}

export interface Contact {
  profileId: string;
  contactId: string;
  contactType: string;
  contactValue: string;
}

export interface Education {
  profileId: string;
  schoolName: string;
  degreeType: string;
  degreeName: string;
  major?: string;
  toDate?: string;
}

export interface PriorJob {
  title: string;
  companyName: string;
  servicePeriod: string;
}

export interface JobsMatched {
  jobId: number;
  profileRef: string;
  proRank: string;
  proScore: string;
  jobTitle: string;
  cityState?: string;
  companyName?: string;
  careerScore: string;
  availabilityScore: string;
  skillScore: string;
  educationScore: string;
}

export interface AnalysisDetail {
  careerScore: ScoreDetail;
  skillScore: ScoreDetail;
  availabilityScore: ScoreDetail;
  educationScore: ScoreDetail;
}

interface ScoreDetail {
  score: string;
  scoreText: string;
  hasInfo: number;
  insights?: { title: string; value: string }[];
}

export interface bulkAddToCampaign {
  jobId: number;
  allIndicator: 0 | 1;
  selectedCandidates: string[];
  priorityIds?: number[];
}

export interface bulkAddToShortList {
  jobId: number;
  priorityId: number;
  candidates: [
    {
      profileRef: string;
    }
  ];
}

export interface bulkChangeStageList {
  jobId: number;
  stageId: number;
  sourcedMethodId: number;
  candidates: [
    {
      profileRef: string;
    }
  ];
}

export interface candidateHideBulk {
  jobId: number;
  isActive: number;
  candidates: {
    profileRef: string;
  }[];
}

export interface CandidateHeaderResponse {
  profileId: number;
  profileRef: string;
  displayName: string;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  homePhone: string;
  companyName: string;
  canRequestContactInfoFlag: number;
  haveResumeFlag: number;
  linkedInUrl: string;
  indeedUrl: string;
  jobTitle: string;
  cityState: string;
  geoId: string;
  showHideReject: any;
  isDataMaskingEnabled: boolean;
  phones: Phone[];
  emails: Email[];
  phonesString: string;
  emailsString: string;
  imgUrl: string;
  positionTypes: any[];
  preferredName: string;
  workStatusId: number;
  workStatusDesc: string;
  availableDate: string;
  maxPayRate: number;
  minPayRate: number;
  payTypeId: number;
  payTypeDesc: string;
  isRestricted: 0 | 1;
  isFollowed: 0 | 1;
  isAts: 0 | 1;
}

export interface CandidateOverviewResponse {
  experience: ExperienceResponse[];
  education: EducationResponse[];
  skills: SkillResponse[];
  certifications: SkillResponse[];
}

export interface ExperienceResponse {
  yearsExperience: number;
  profileId: number;
  companyName: string;
  title: string;
  fromDate: string;
  toDate: string;
}

export interface EducationResponse {
  profileId: number;
  schoolName: string;
  degreeName: string;
  major: string;
  toDate: string;
  fromDate: string;
}

export interface SkillResponse {
  lastUsed: number;
  yearsExperience: number;
  profileId: number;
  skillId: number;
  skillName: string;
  skillType: string;
}

export interface JobMatchResponse {
  activeProjects: ActiveJob[];
  projectHistory: ActiveJob[];
  otherProjectsmatched: ActiveJob[];
}

export interface ActiveJob {
  assignedRecruiter: string;
  nextOutreachDate: string;
  jobTitle?: string;
  companyName: string;
  stageId: number;
  stageDesc: string;
  priorityId: number;
  priorityDesc: string;
  lastContactedDate: string;
}

export interface Pagination {
  offset: number;
  numResults: number;
  totalEntries: number;
}
export interface FileVersion {
  fileName: string;
  dateCreated: string;
  downloadToken: string;
}

export interface Activity {
  summaryDesc: string;
  dateGroup: string;
  title: string;
  createdDate: number;
}

export interface Phone {
  contactId?: number;
  phoneNumber: string;
  contactType?: number;
  isPrimary?: number;
  isValid?: number;
  valid?: number;
}

export interface Email {
  emailTypeId?: number;
  email: string;
  isPrimary?: number;

  emailId?: number;
  isValid?: number;
  grade?: string;
  valid?: number;
}

export interface updateContactInfoBody {
  profileRef: string;
  phones: Phone[];
  emails: Email[];
}

export interface WorkPreferences {
  payTypeId?: number;
  positionTypeId?: number[];
  availableDate?: string;
  minPayRate?: string;
  maxPayRate?: string;
}
